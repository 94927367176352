import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import modelPath from '../glb/landscape.glb'


export function Model(props) {
    const group = useRef()
    const { nodes, materials, animations } = useGLTF(modelPath)
    const { actions } = useAnimations(animations, group)
    useEffect(() => {
        //console.log('actions:', actions.Animation.play())
    })
    return (
        <group {...props} dispose={null}>
            <mesh
                //castShadow
                //receiveShadow
                geometry={nodes.plan1.geometry}
                material={materials.m_ground}
            />
            <mesh
                //castShadow
                //receiveShadow
                geometry={nodes.plan2.geometry}
                material={materials.m_ground}
            />
            <mesh
                //castShadow receiveShadow 
                geometry={nodes.hdr2.geometry}
                material={materials.m_ground} />
        </group>
    )
}

useGLTF.preload(modelPath)