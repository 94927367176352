import { useState, useEffect, memo, useRef } from 'react';
import { DirectionalLightHelper, MathUtils, Object3D, Vector3 } from 'three';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, Sphere, useGLTF, Center, AccumulativeShadows, RandomizedLight, Environment, useHelper, CameraShake, PerspectiveCamera, Float, Stats } from '@react-three/drei';
import { VRButton, XR, Controllers, Hands, useTeleportation, useXR } from '@react-three/xr'
import { useControls, Leva } from 'leva';

//import { THREE } from 'expo-three';

//import { Model } from '../assets/js/model_01';
import { Model } from '../assets/js/landscape_01';
import SphereModel from '../assets/js/sphere'


export function App() {
    const [session, setSession] = useState(false)

    const { ambientLightIntensity, movementSpeed, movementIntensity, movementRange, rotationIntensity, VRMovement, showStats } = useControls({

        ambientLightIntensity: { value: 2, min: 0, max: 5, step: 0.1, label: 'Ambient Light intensity' },
        movementSpeed: { value: 1, min: 0, max: 5, step: .1, label: 'Movement speed' },
        movementIntensity: { value: 2, min: 0, max: 10, step: .1, label: 'Movement intensity' },
        //movementRange: [-0.1, 0.1],
        rotationIntensity: { value: 1, min: 0, max: 10, step: .1, label: 'Rotation intensity' },
        VRMovement: { value: false, label: 'In VR movement' },
        showStats: { value: false, label: 'Show stats' }
    })

    // player in ue (X=-14042.495345,Z=60.640623,Y=1983.821343)
    // (X=-9887.053991,Z=30.700844,Y=637.274088)
    // (X=-14042.495345,Y=60.640623,Z=1983.821343)
    //const playerOffset = new Vector3(-9887.053991, 637.274088, 30.700844)

    const playerOffset = new Vector3(-14042, 983, 60)
    const main_position = new Vector3(-14036.553, 1145.764, 59.418)

    const playerPos = new Vector3()
    playerPos.copy(main_position)
    playerPos.sub(playerOffset)
    main_position.sub(playerPos)

    const p = main_position.copy(main_position.multiplyScalar(-.01))

    const playerPosition = (player) => {
        /* player.position.x = 2.5
        player.position.y = 0
        player.position.z = .5 */
        player.position.x = 0
        player.position.y = -1
        player.position.z = .5
    }

    const Teleport = () => {
        const { isPresenting, player } = useXR()
        useEffect(() => {
            if (isPresenting) {
                playerPosition(player)
            }
        }, [isPresenting])
        console.log('teleport')
    }

    const Lights = () => {
        const d = 5
        const directionalLightRef = useRef(null);
        useHelper(directionalLightRef, DirectionalLightHelper, 1, 'red')
        return (
            <>
                {/* <directionalLight
                    ref={directionalLightRef}
                    position={[0, 3, 3]}
                    target={new Object3D()}
                    intensity={1}
                    castShadow={true}
                    shadow-mapSize-height={1024}
                    shadow-mapSize-width={1024}
                    shadow-camera-left={-d}
                    shadow-camera-right={d}
                    shadow-camera-top={d}
                    shadow-camera-bottom={-d}
                    shadow-camera-far={20}
                /> */}
                <ambientLight intensity={ambientLightIntensity} />
            </>
        )
    }

    const onSessionStart = () => {
        console.log('start')
        setSession(true)
    }

    const onSessionEnd = () => {
        console.log('stop')
        setSession(false)
    }

    return (
        <>
            <Leva
                collapsed={true}
                oneLineLabels={true}
                theme={{
                    colors: {
                        elevation1: 'rgba(41,45,57,.8)',
                        elevation2: 'rgba(30,35,39,.8)'
                    },

                    sizes: { rootWidth: '200px' }
                }}
            />
            <VRButton />
            <Canvas shadows camera={{ position: [0, 0, .01], far: 100000 }} color='white' >
                {showStats ? <Stats /> : <></>}
                <XR referenceSpace="local-floor" style onSessionStart={onSessionStart} onSessionEnd={onSessionEnd}>
                    <Controllers />
                    {/* <Hands /> */}
                    <Teleport />

                    {/* <group scale={[100, 100, 100]} position={[-14036.553 * -.01 - (-14042 * -.01), 1145.764 * -.01 - (900 * -.01), 59.418 * -.01 - (60 * -.01)]}>
                        <SphereModel />
                    </group> */}

                    <Float
                        speed={session & !VRMovement ? 0 : movementSpeed}
                        floatIntensity={movementIntensity}
                        //floatingRange={movementRange}
                        rotationIntensity={rotationIntensity}                        
                    >
                        <group rotation={[0, MathUtils.degToRad(230), 0]}>
                            <group position={[p.x, p.y, p.z]}>
                                <group scale={[1, 1, 1]}>
                                    <Model />
                                </group>
                            </group>
                        </group>
                    </Float>

                    <Lights />
                    <OrbitControls makeDefault />
                </XR>
            </Canvas>
        </>
    );

}

export default App;
