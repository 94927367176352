import './App.css';
import { useState, useEffect, memo, useRef, Suspense } from 'react';
//import { StyleSheet, Text, View } from 'react-native';
import { DirectionalLightHelper, MathUtils, Object3D } from 'three';
import { Canvas, useThree } from '@react-three/fiber';

import { OrbitControls, Sphere, useGLTF, Center, AccumulativeShadows, RandomizedLight, Environment, GizmoHelper, GizmoViewport, Plane, useHelper } from '@react-three/drei';
import { Grid } from '@react-three/drei';
//import { Model } from './assets/js/model_01';
import { Model } from './assets/js/landscape_01';
//import { View } from 'react-native';



import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { VRButton, ARButton, XR, Controllers, Hands, useTeleportation, useXR } from '@react-three/xr'
//import { THREE } from 'expo-three';
import Scene from './components/scene02'
function App() {

  return (
    <>
      <Suspense fallback={false}>
        <Scene />
      </Suspense>
    </>
  );

}

export default App;
